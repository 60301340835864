<template>
  <create-item-component
      endpoint="unwows"
      type="unwow"/>
</template>
<script>

export default {
  name: "Unwows"
}
</script>